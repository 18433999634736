@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes gradient-x {
    0% {
        background-color: #007bff;
        /* Starting color */
    }

    50% {
        background-color: #00c9ff;
        /* Middle color */
    }

    100% {
        background-color: #007bff;
        /* End color */
    }
}

.animate-gradient-x {
    animation: gradient-x 5s ease infinite;
    /* Animate color transition over 5 seconds */
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in-up {
    animation: fadeInUp 0.6s ease-out forwards;
}

.animation-delay-200 {
    animation-delay: 200ms;
}

.animation-delay-400 {
    animation-delay: 400ms;
}

.prose {
    max-width: 65ch;
    line-height: 1.75;
}

.prose p {
    margin-bottom: 1.5em;
}

.investment-slider .slick-dots {
    bottom: -40px;
}

.investment-slider .slick-dots li button:before {
    font-size: 12px;
    color: #0059B2;
}

.investment-slider .slick-dots li.slick-active button:before {
    color: #0059B2;
}

.backdrop-blur-md {
    backdrop-filter: blur(8px);
}

@media (max-width: 768px) {
    .backdrop-blur-md {
        backdrop-filter: blur(4px);
    }
}

.text-inherit {
    color: inherit;
}

/* Better button handling on mobile */
@media (max-width: 640px) {
    .inline-flex {
        width: 100%;
    }
}

/* Improve text contrast */
.text-gray-700 {
    color: #374151;
}

.text-gray-900 {
    color: #111827;
}

/* Ensure button text stays visible on hover */
.hover\:text-primary:hover>span {
    color: #0059B2;
}